<template>
  <div class="py-10" style="background-color:#fff !important;height:100vh">

    <div class="py-6">
      <v-list class="px-2">
        <v-list-item>
          <v-list-item-avatar size="60">
            <v-img
              style="position: relative; z-index: 1; border-radius: 50%; border: 4px solid #ecf0f1"
              contain
              :src="user.profile.image_url">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    indeterminate
                    color="#d31145">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="body-1 font-weight-bold">
              {{ user.profile.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ user.profile.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list>
        <v-list-item-group>
          <v-list-item class="px-4" to="/config/profile">
            <v-list-item-content>
              <v-list-item-title>Profil</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="#D0D0D0">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider class="mx-auto"></v-divider>

          <v-list-item class="px-4" @click="$store.dispatch('not_found')">
            <v-list-item-content>
              <v-list-item-title>Konfigurasi PIN</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="#D0D0D0">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider class="mx-auto"></v-divider>


          <v-list-item class="px-4" @click="$store.dispatch('not_found')">
            <v-list-item-content>
              <v-list-item-title>Bantuan</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="#D0D0D0">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider class="mx-auto"></v-divider>

        </v-list-item-group>
      </v-list>

    </div>
  </div>
</template>

<script>
export default {
  data: () => ({

  }),
  components: {

  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  watch: {

  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>
